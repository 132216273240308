import { useEffect } from 'react';
import { useQuery } from 'react-query';
import { useSetRecoilState } from 'recoil';
import { Outlet } from '@tanstack/react-location';
import { Box, useBreakpointValue } from '_shared/designSystem/components';
import { authenticationService } from 'authentication/services/authenticationService';
import { userPermissionsState } from '_shared/globalState/atoms';
import MobileHeader from '_shared/components/mainMenu/MobileHeader';
import ErrorBoundary from '_shared/errors/ErrorBoundary';
import MainPageError from '_shared/errors/MainPageError';
import TopMenu from '_shared/components/mainMenu/TopMenu';
import { topMenuHeight } from './margins';
import AblyConnection from 'authentication/components/AblyConnection';

const MainLayout = () => {
  const setUserPermissionsState = useSetRecoilState(userPermissionsState);
  const windowSizeIsMd = useBreakpointValue({ md: true });

  const { data } = useQuery(['authenticationService_getUserPermissions'], () =>
    authenticationService.getUserPermissions()
  );

  useEffect(() => {
    if (data) setUserPermissionsState(data);
  }, [data, setUserPermissionsState]);

  if (hideSideMenu()) return <Outlet />;
  return <AblyConnection>{windowSizeIsMd ? <TopMenuLayout /> : <MobileLayout />}</AblyConnection>;
};

export default MainLayout;

function hideSideMenu() {
  // Would prefer to do this from react-location, but could't figure out how to do it. If you know how, please submit a PR with the solution
  const currentUrl = window.location.href;
  const doNotShowSideMenu = ['match-report', 'login'];
  let sideMenuHide = false;
  doNotShowSideMenu.forEach((item) => {
    if (currentUrl.includes(item)) {
      sideMenuHide = true;
    }
  });
  return sideMenuHide;
}

const TopMenuLayout = () => {
  return (
    <Box>
      <Box position="fixed" top={0} left={0} zIndex={3} w="100vw">
        <TopMenu />
      </Box>
      <Box h={`${topMenuHeight}px`} />
      <Box>
        <ErrorBoundary renderError={() => <MainPageError />}>
          <Outlet />
        </ErrorBoundary>
      </Box>
    </Box>
  );
};

const MobileLayout = () => (
  <Box>
    <Box position="sticky" top={0} zIndex={3}>
      <MobileHeader />
    </Box>
    <Box>
      <ErrorBoundary renderError={() => <MainPageError />}>
        <Outlet />
      </ErrorBoundary>
    </Box>
  </Box>
);
