import { ChannelProvider, useChannel } from 'ably/react';

import { Box } from '_shared/designSystem/components';
import { isDevOrTest } from '_shared/utils/environment/currentEnv';
import { matchStatus } from '_shared/constants/matchTypes';

export const AblyUpdater = ({ matchId, matchStatus: _matchStatus, updateDataFunction }) => {
  const ablyChannelName = `match:${matchId}`;
  return !isDevOrTest() && _matchStatus === matchStatus.LIVE ? (
    <ChannelProvider channelName={ablyChannelName}>
      <AblyMessageHandler ablyChannelName={ablyChannelName} updateDataFunction={updateDataFunction} />
    </ChannelProvider>
  ) : (
    <Box />
  );
};

const AblyMessageHandler = ({ ablyChannelName, updateDataFunction }) => {
  useChannel(ablyChannelName, 'header', (message) => {
    const data = message?.data;
    const newLiveScore = {
      match_status: data?.match_status,
      match_score: {
        set_scores: data?.match_score?.set_scores,
        in_progress_game_score: data?.match_score?.in_progress_game_score
      },
      match_result: data?.match_result
    };
    updateDataFunction(newLiveScore);
  });

  return <Box />;
};
