export function getCurrentEnvironment() {
  if (process.env.NODE_ENV === 'development') return 'dev';
  if (process.env.NODE_ENV === 'test') return 'test';
  if (process.env.NODE_ENV === 'production') {
    if (process.env.REACT_APP_ENV === 'staging') return 'staging';
    if (process.env.REACT_APP_ENV === 'prod') return 'prod';
    return 'staging';
  }
  return null;
}

export const isDevOrTest = () => ['dev', 'test'].includes(getCurrentEnvironment());
